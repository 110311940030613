<template>
  <div class="home">
    <!-- 头部 -->
    <div>
      <headerTop></headerTop>
      <div class="Top">
        <div class="btn" @click="register">立即注册</div>
      </div>
    </div>
    <!-- 产品 -->
    <div class="product" id="target-element">
      <p class="productTitle">我们有哪些产品</p>
      <div class="productTab">
        <div :class="tabIndex == 0?'is-active':'tabItem'" @click="tabitem(0)">
          <img v-if="tabIndex == 0" src="../assets/tab1-active.png" alt="">
          <img v-else src="../assets/tab1.png" alt="">
          <p class="productTab-title">全球旅游资源供应</p>
        </div>
        <div :class="tabIndex == 1?'is-active':'tabItem'" @click="tabitem(1)">
          <div :class="tabIndex == 1?'is-active':'tabItem'" @click="tabitem(0)">
            <img v-if="tabIndex == 1" src="../assets/tab2-active.png" alt="">
            <img v-else src="../assets/tab2.png" alt="">
            <p class="productTab-title">全球酒店资源API接口</p>
          </div>
        </div>
        <div :class="tabIndex == 2?'is-active':'tabItem'" @click="tabitem(2)">
          <div :class="tabIndex == 2?'is-active':'tabItem'" @click="tabitem(0)">
            <img v-if="tabIndex == 2" src="../assets/tab3-active.png" alt="">
            <img v-else src="../assets/tab3.png" alt="">
            <p class="productTab-title">技术服务</p>
          </div>
        </div>
        <!-- <div :class="tabIndex == 3?'is-active':'tabItem'" @click="tabitem(3)">
          <div :class="tabIndex == 3?'is-active':'tabItem'" @click="tabitem(0)">
            <img v-if="tabIndex == 3" src="../assets/tab4-active.png" alt="">
            <img v-else src="../assets/tab4.png" alt="">
            <p class="productTab-title">系统定制开发</p>
          </div>
        </div> -->
      </div>
      <div class="productConst" v-show="tabIndex == 0 || tabIndex == 3">
        <div class="constItem constItem1">
          <p class="p1">海量的酒店</p>
          <p class="p2">全球超100万家酒店资源供应</p>
        </div>
        <div class="constItem constItem2">
          <p class="p1">多种合作模式</p>
          <p class="p2">我们加价、底价、定制三种合作模式，您可以根据您的业务模式灵活</p>
        </div>
        <div class="constItem constItem3">
          <p class="p1">订单人工核验</p>
          <p class="p2">您的所有订单我们都提供人工核验，人工检查所有订单细节尽可能确保您的客户顺利入住</p>
        </div>
        <div class="constItem4">
          <p class="p1">支持B2B网站下单：</p>
          <p class="p2">我们提供简单易操作的B2B网站，您可以直接下单并在线支付</p>
        </div>
        <div class="constItem5">
          <p class="p1">支持API接口对接：</p>
          <p class="p2">您可以通过我们的API开放接口对接酒店资源，大大丰富您的酒店资源</p>
        </div>
      </div>
      <div class="productConst" v-show="tabIndex == 1">
        <div class="constItem constItem1" style="border-radius: 0 0 0 30rem;">
          <p class="p1">全球酒店资源API：</p>
          <p class="p2">我们提供简单易对接的API接口，对接后您即可获得超过100万家全球酒店资源</p>
        </div>
        <div class="constItem constItem2">
          <p class="p1">酒店以及酒店房型映射API：</p>
          <p class="p2">我们提供酒店及酒店房型映射服务，能将多个供应商的酒店和酒店房型进行聚合</p>
        </div>
        <div class="constItem constItem3" style="border-radius: 0rem 0 30rem 0rem;">
          <p class="p1">旅游达人图文/视频攻略API：</p>
          <p class="p2">数万篇精选旅游达人图文、视频笔记可通过API接口轻松对接至您的应用程序（即将上线）</p>
        </div>
        <!-- <div class="constItem4">
          <p class="p1">支持B2B网站下单：</p>
          <p class="p2">我们提供简单易操作的B2B网站，您可以直接下单并在线支付</p>
        </div>
        <div class="constItem5">
          <p class="p1">支持API接口对接：</p>
          <p class="p2">您可以通过我们的API开放接口对接酒店资源，大大丰富您的酒店资源</p>
        </div> -->
      </div>
      <div class="productConst" v-show="tabIndex == 2">
        <div class="constItem constItem1" style="border-radius: 0 0 0 30rem;">
          <p class="p1">全球酒店供应商接口对接服务：</p>
          <p class="p2">我们提供第三方酒店批发商API快速对接服务，并支持酒店以及酒店房型映射</p>
        </div>
        <div class="constItem constItem2">
          <p class="p1">B2C微信小程序：</p>
          <p class="p2">让您在1周内就可以拥有部署在您服务器上的全球酒店预订软件，可供您的客户在微信小程序自助下单</p>
        </div>
        <div class="constItem constItem3" style="border-radius: 0 0 30rem 0;">
          <!-- <p class="p1">B2C微信小程序：</p>
          <p class="p2">让您在1周内就可以拥有部署在您服务器上的全球酒店预订软件，可供您的客户在微信小程序自助下单</p> -->
        </div>
        <!-- <div class="constItem4">
          <p class="p1">支持B2B网站下单：</p>
          <p class="p2">我们提供简单易操作的B2B网站，您可以直接下单并在线支付</p>
        </div>
        <div class="constItem5">
          <p class="p1">支持API接口对接：</p>
          <p class="p2">您可以通过我们的API开放接口对接酒店资源，大大丰富您的酒店资源</p>
        </div> -->
      </div>
    </div>
    <!-- 关于我们 -->
    <div class="aboutUs" id="target-element2"></div>
    <!-- 付费资讯 -->
    <div style="display:none;" class="pay" id="target-element3">
      <p class="partner-title">付费资讯</p>
      <div class="pay-box">
        <div class="pay-item">
          <img src="../assets/dny.png" alt="">
          <div class="flex">
            <div class="pay-title">东南亚出入境数据</div>
            <div class="pay-content">
              岁末年初，多个东南亚国家出台签证利好政策，东南亚出境游市场复苏进行预计将加速，我们统计了东南亚各国历年中国人入境数据。
            </div>
            <div class="pay-bottom">
              <div class="pay-price">
                ￥99.00
              </div>
              <div class="pay-see" @click="dialogVisible=true">
                付费查看
              </div>
            </div>
          </div>
        </div>
        <div class="pay-item">
          <img src="../assets/rh.png" alt="">
          <div class="flex">
            <div class="pay-title">日韩出入境数据</div>
            <div class="pay-content">
              2023年全年入境日本人数累计超过2500万人，韩国取代中国成为日本第一大客源国，我们统计了日本和韩国历年中国人入境数据。
            </div>
            <div class="pay-bottom">
              <div class="pay-price">
                ￥99.00
              </div>
              <div class="pay-see" @click="dialogVisible=true">
                付费查看
              </div>
            </div>
          </div>
        </div>
        <div class="pay-item">
          <img src="../assets/ga.png" alt="">
          <div class="flex">
            <div class="pay-title">港澳出入境数据</div>
            <div class="pay-content">
              自2023年香港与内地恢复通关以来港澳游持续恢复，过去1年间，超5000万人次港人北上，我们统计了中国内地居民入境港澳数据。
            </div>
            <div class="pay-bottom">
              <div class="pay-price">
                ￥99.00
              </div>
              <div class="pay-see" @click="dialogVisible=true">
                付费查看
              </div>
            </div>
          </div>
        </div>
        <div class="pay-item">
          <img src="../assets/fz.png" alt="">
          <div class="flex">
            <div class="pay-title">中东非洲出入境数据</div>
            <div class="pay-content">
              航班恢复度高、签证方便等各方面都比较友好的阿联酋成为2023年出境游市场的一匹黑马，我们统计了中东非洲地区中国人入境数据。
            </div>
            <div class="pay-bottom">
              <div class="pay-price">
                ￥99.00
              </div>
              <div class="pay-see" @click="dialogVisible=true">
                付费查看
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div class="popup">
        <div class="popup-title">扫码付款后可阅读文章</div>
        <img class="ewm" src="../assets/ewm.png" alt="">
        <div class="btns">
          <div class="btn1" @click="dialogVisible=false">返回</div>
          <div class="btn2" @click="dialogVisible=false">我已付款</div>
        </div>
      </div>
    </el-dialog>
    <!-- 合作方 -->
    <div class="partner">
      <p class="partner-title">我们的合作方</p>
      <div class="partnerBox">
        <ul>
          <!-- <li :class="tabIndex2==0?'partner-tab':''" @click="partnertab(0)">酒店伙伴</li> -->
          <li :class="tabIndex2==1?'partner-tab':''" @click="partnertab(1)">供应商</li>
          <li :class="tabIndex2==2?'partner-tab':''" @click="partnertab(2)">技术合作伙伴</li>
        </ul>
        <div class="partnerImgs" v-if="tabIndex2==1">
          <img src="../assets/1-1.png" alt="">
          <img src="../assets/1-2.png" alt="">
          <img src="../assets/1-3.png" alt="">
          <img src="../assets/1-4.png" alt="">
          <img src="../assets/1-5.png" alt="">
          <img src="../assets/1-6.png" alt="">
          <img src="../assets/1-7.png" alt="">
        </div>
        <div class="partnerImgs" v-if="tabIndex2==2">
          <img src="../assets/1-8.png" alt="">
          <img src="../assets/1-9.png" alt="">
          <img src="../assets/1-10.png" alt="">
        </div>
        <!-- <img src="../assets/3.png" alt=""> -->
      </div>
    </div>
    <!-- 注册 -->
    <div class="register">
      <!-- <div class="registerBtn" >立即注册</div> -->
    </div>
    <fottom></fottom>
  </div>
</template>

<script>
// @ is an alias to /srcfottom
import headerTop from '@/components/headerTop.vue'
import fottom from '@/components/fottom.vue'

export default {
  name: 'Home',
  components: {
    headerTop,
    fottom
  },
  data () {
    return {
      dialogVisible:false,
      tabIndex:0,
      tabIndex2:1,
      img1:[
        '../assets/1-1.png',
        '../assets/1-2.png',
        '../assets/1-3.png',
        '../assets/1-4.png',
        '../assets/1-5.png',
        '../assets/1-6.png',
        '../assets/1-7.png',
      ],
      img2:[
        '../assets/1-8.png',
        '../assets/1-9.png',
        '../assets/1-10.png'
      ],
    };
  },
  computed: {},

  mounted(){},

  methods: {
    tabitem(x){
      this.tabIndex = x
    },
    partnertab(x){
      this.tabIndex2 = x
    },
    register(){
      console.log(1111);
      this.$router.push('/about')
    }
  }
}
</script>
<style scoped>
.Top{
  width: 100%;
  height: 739rem;
  background-image: url(../assets/1.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 1rem;
  box-sizing: border-box;
}
.btn{
  width: 228rem;
  height: 56rem;
  background-image: linear-gradient(79deg, #EE755C 0%, #E62F7B 38%, #3A479D 100%);
  border-radius: 39rem;
  line-height: 56rem;
  text-align: center;
  font-weight: 400;
  font-size: 20rem;
  color: #FFFFFF;
  margin-top: 490rem;
  margin-left: 210rem;
  cursor:pointer;
}
.product{
  background: #F5F5F5;
  padding: 1rem 50rem 0 50rem;
  box-sizing: border-box;
}
.productTitle{
  font-weight: 400;
  font-size: 50rem;
  color: #000000;
  text-align: center;
  margin-top: 70rem;
}
.productTab{
  /* width: 425rem; */
  width: 100%;
  height: 332rem;
  display: flex;
  justify-content: space-between;
  margin-top: 108rem;
}
.tabItem{
  /* width: 425rem; */
  width: 580rem;
  height: 300rem;
  background: #FFFFFF;
  border-radius: 20rem;
}
.is-active{
  /* width: 425rem; */
  width: 580rem;
  height: 332rem;
  background: #FFFFFF;
}
.tabItem img{
  width: 425rem;
  height: 267rem;
  margin-top: -76rem;
}
.is-active img{
  width: 425rem;
  height: 267rem;
  margin-top: -76rem;
}
.productTab-title{
  font-weight: 600;
  font-size: 30rem;
  color: #000000;
  margin-top: 16rem;
  margin-left: 50rem;
}
.productConst{
  display: flex;
  flex-wrap: wrap;
}
.constItem{
  width: 600rem;
  height: 255rem;
  background: #FFFFFF;
  padding: 80rem 85rem 0 66rem;
  box-sizing: border-box;
}
.constItem1{
  background-image: url(../assets/constItem1.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.constItem2{
  background-image: url(../assets/constItem2.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.constItem3{
  background-image: url(../assets/constItem3.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.constItem4{
  width: 600rem;
  height: 255rem;
  background: #FFFFFF;
  border-radius: 0 0 0 30rem;
  background-image: url(../assets/constItem4.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80rem 85rem 0 66rem;
  box-sizing: border-box;
}
.constItem5{
  width: 1200rem;
  height: 255rem;
  background: #FFFFFF;
  border-radius: 0 0 30rem 0;
  background-image: url(../assets/constItem5.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80rem 206rem 0 66rem;
  box-sizing: border-box;
}
.productConst .p1{
  font-weight: 600;
  font-size: 30rem;
  color: #000000;
}
.productConst .p2{
  font-weight: 400;
  font-size: 20rem;
  color: #8A8A8A;
  margin-top: 10rem;
}
.aboutUs{
  width: 100%;
  height: 650rem;
  background-image: url(../assets/2.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -54rem;
}
.partner{
  padding: 113rem 87rem 96rem 117rem;
  background: #FFFFFF;
}
.partner-title{
  font-weight: 400;
  font-size: 50rem;
  color: #000000;
}
.partnerBox{
  margin-top: 63rem;
  display: flex;
  justify-content: space-between;
}
.partnerBox ul{
  padding-top: 12rem;
}
.partnerBox li{
  padding-left: 10rem;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 16rem;
  color: #000000;
  margin-bottom: 44rem;
  cursor:pointer;
}
.partner-tab{
  padding-left: 10rem;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 16rem;
  color: #EA4C6F !important;
  margin-bottom: 44rem;
  position: relative;
}
.partner-tab::after{
    content: "";
    position: absolute;
    left: 0%;
    transform: translateY(25%);
    width: 3rem;
    height: 16rem;
    background-image: linear-gradient(13deg, #EE755C 0%, #E62F7B 38%, #3A479D 100%);
    border-radius: 1.25rem;
}
.partnerImgs{
  flex: 1;
  padding-left: 30rem;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.partnerImgs img{
  display: inline-block;
  margin-right: 42rem;
  margin-bottom: 56rem;
  width: 244rem !important;
  height: 129rem !important;
}
.partnerBox img{
  width: 1535rem;
  height: 656rem;
}
.register{
  width: 100%;
  height: 72rem;
  background-image: url(../assets/4.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 1rem;
}
.registerBtn{
  width: 134rem;
  height: 38rem;
  background: #FFFFFF;
  border-radius: 18.79rem;
  margin-top: 16rem;
  margin-left: 1050rem;
  font-weight: 600;
  font-size: 17.53rem;
  color: #ED725C;
  text-align: center;
  line-height: 38rem;
  cursor:pointer;
}
.pay{
  padding: 90rem 129rem 90rem 117rem;
  box-sizing: border-box;
  background: #F5F5F5;
}
.pay-box{
  margin-top: 32rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.pay-item{
  width: 820rem;
  height: 338rem;
  background: #FFFFFF;
  margin-bottom: 20rem;
  padding: 57rem 50rem 56rem 45rem;
  box-sizing: border-box;
  display: flex;
}
.pay-item img{
  width: 225rem;
  height: 225rem;
}
.flex{
  flex:1;
  padding-left: 65rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.pay-title{
  font-weight: 400;
  font-size: 24rem;
  color: #000000;
}
.pay-content{
  font-weight: 600;
  font-size: 16rem;
  color: #A8A8A8;
}
.pay-bottom{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pay-price{
  font-weight: 600;
  font-size: 20rem;
  color: #000000;
}
.pay-see{
  width: 134rem;
  height: 39rem;
  background-image: linear-gradient(77deg, #EE755C 0%, #E62F7B 38%, #3A479D 100%);
  border-radius: 39rem;
  font-weight: 400;
  font-size: 18rem;
  color: #FFFFFF;
  line-height: 39rem;
  text-align: center;
  cursor:pointer;
}
.popup{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popup-title{
  font-weight: 600;
  font-size: 20rem;
  color: #000000;
}
.ewm{
  width: 260rem;
  height: 350rem;
  margin-top: 25rem;
}
.btns{
  display: flex;
  margin-top: 65rem;
}
.btn1{
  width: 184rem;
  height: 42rem;
  background: #c8c8c8ad;
  border-radius: 21rem;
  font-weight: 500;
  font-size: 16rem;
  color: #000000;
  text-align: center;
  line-height: 42rem;
}
.btn2{
  width: 184rem;
  height: 42rem;
  background-image: linear-gradient(80deg, #EE755C 0%, #E62F7B 38%, #3A479D 100%);
  border-radius: 21rem;
  font-weight: 500;
  font-size: 16rem;
  color: #FFFFFF;
  text-align: center;
  line-height: 42rem;
  margin-left: 15rem;
  cursor:pointer;
}
</style>