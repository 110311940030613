import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/js/rem'
// 导入并配置axios
import axios from "axios"; //导入axios
// 导入element-ui
import ElementUI from "element-ui";
// 导入elemet-ui中的样式文件
import "element-ui/lib/theme-chalk/index.css";
// 注册elemetui
Vue.use(ElementUI);
//把axios通过$http属性挂载到Vue原型上，每一个vue实例都可以使用
Vue.prototype.$http = axios; 
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
