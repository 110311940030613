<!--  -->
<template>
    <div class="fottom">
        <div class="fottom-top">
            <div class="top1">
                <p class="p1">联系方式</p>
                <p class="p2">商务合作</p>
                <p class="p2">酒店及供应商合作</p>
            </div>
            <div class="top2">
                <p>官方商务 +86 18516919378</p>
                <p>沈先生&ensp;&ensp; +86 18611699665</p>
                <p>suppliercontact@wanzicloud.com</p>
            </div>
            <div class="top3">
                <div class="top3-p">
                    <p>business@wanzicloud.com</p>
                    <p>shendong@wanzicloud.com</p>
                    <!-- <p>hahacloud@haha.com（国际客户）</p> -->
                </div>
            </div>
            <div class="wx">
                    <p>关注我们</p>
                    <div>
                        <img src="../assets/wx.png" alt="">
                        <div style="margin-top:33rem;">
                            <img src="../assets/ewm.jpg" alt="" style="width: 142rem;height: 142rem;">
                        </div>
                    </div>
                </div>
        </div>
        <div class="fottom-botm">
            <!-- 京B2-20191025  gov京公网安备 32041202001826号 -->
            <p style="margin-top: 40rem;">©玩子云科技有限公司 All Rights Reserved  京ICP备19044258号-25  京B2-20191025  gov京公网安备 32041202001826号</p>
            <!-- <p>广东省深圳市罗湖区吉祥路380号</p> -->
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
        };
    },

    components: {},

    computed: {},

    mounted(){},

    methods: {}
}

</script>
<style scoped>
.fottom{
    width: 100%;
    height: 491rem;
    background-image: linear-gradient(76deg, #EE755C 0%, #E62F7B 38%, #3A479D 100%);
    border-top: 1rem solid #FFFFFF;
    /* background-image: url(../assets/5.png);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover; */
}
.fottom-top{
    display: flex;
    height: 403rem;
    padding: 76rem 0 0 120rem;
    box-sizing: border-box;
}
.top1 .p1{
    font-weight: 600;
    font-size: 30rem;
    color: #FFFFFF;
    margin-top: 11rem;
    margin-bottom: 60rem;
}
.top1 .p2{
    font-weight: 600;
    font-size: 20rem;
    margin-bottom: 82rem;
    color: #FFFFFF;
}
.top2{
    margin-top: 113rem;
    margin-left: 162rem;
}
.top2 p{
    font-weight: 400;
    font-size: 20rem;
    margin-bottom: 28rem;
    color: #FFFFFF;
}
.top3{
    margin-top: 60rem;
    margin-left: 110rem;
}
.wx{
    display: flex;
    margin-left: 80rem;
    /* align-items: center; */
}
.wx p{
    font-weight: 600;
    font-size: 30rem;
    color: #FFFFFF;
}
.wx img{
    width: 63rem;
    height: 63rem;
    margin-left: 75rem;
}
.top3-p{
    font-weight: 400;
    font-size: 20rem;
    color: #FFFFFF;
    margin-top: 50rem;
}
.top3-p p{
    margin-bottom: 28rem;
}
.fottom-botm{
    width: 100%;
    border-top: 1rem solid #FFFFFF;
    text-align: center;
}
.fottom-botm p{
    font-weight: 400;
    font-size: 14rem;
    color: #DFDFDF;
    margin-top: 10rem;
}
</style>