<template>
  <div class="headerTop">
    <div class="aaa">
      <img src="../assets/wzy.png" alt="">
      <div @click="link(1)">产品</div>
      <div @click="link(2)">关于我们</div>
      <!-- <div @click="link(3)">行业资讯</div> -->
    </div>
    <div @click="login()" style="font-size: 20rem;"><a href="http://pc.wanzicloud.com">登录</a></div>
  </div>
</template>

<script>
export default {
  name: 'headerTop',
  props: {
    msg: String
  },
  methods: {
    link(x) {
      if(x==1){
        const element = document.getElementById("target-element");
        element.scrollIntoView({ behavior: "smooth" });
      }else if(x==2){
        const element = document.getElementById("target-element2");
        element.scrollIntoView({ behavior: "smooth" });
      }else{
        const element = document.getElementById("target-element3");
        element.scrollIntoView({ behavior: "smooth" });
      }
    	
    },
    login(){
      // window.localStorage.href = 'http://pc.wanzicloud.com'
    }
  }
}
</script>

<style scoped>
.headerTop{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 60rem;
  position: absolute;
  padding-top: 20rem;
  box-sizing: border-box;
}
.headerTop img{
  width: 110rem;
  height: 40rem;
  margin-right: 137rem;
}
div{
  font-weight: 400;
  font-size: 15.17rem;
  color: #FFFFFF;
  margin-right: 90rem;
  cursor:pointer;
}
.aaa{
  display: flex;
  align-items: center;
}
a{
  color: #FFFFFF;
  text-decoration:none;
}
</style>
