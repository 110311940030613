<!--  -->
<template>
    <div>
        <div class="froms">
            <div class="title">欢迎注册玩子云账号</div>
            <div class="tips">我们在您提交注册信息后24小时内会进行信息核对，届时请您留意注册激活邮件。</div>
            <div class="step">
            <div class="step1">
                <div class="red">1</div>
                <div class="tips_min">注册填写</div>
            </div>
            <div class="step2">
                <div class="red red_no">2</div>
                <div class="tips_min">信息审核</div>
            </div>
            </div>
            <div class="title2">机构信息已提交审核</div>
            <div class="tips">感谢您注册成为玩子云用户，您提交的机构信息审核通过即可登录</div>
        </div>
        <div class="btn" @click="shouye">确定</div>
        <div class="bto">
            <fottom></fottom>
        </div>
    </div>
</template>

<script>
import fottom from '@/components/fottom.vue'
export default {
    data () {
        return {

        };
    },
    components: {
        fottom
    },

    computed: {},

    mounted(){},

    methods: {
      shouye(){
        this.$router.push('/')
      }
    }
}

</script>
<style scoped>
.btn{
  width: 228rem;
  height: 56rem;
  background-image: linear-gradient(79deg, #EE755C 0%, #E62F7B 38%, #3A479D 100%);
  border-radius: 39rem;
  font-weight: 400;
  font-size: 20rem;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
  line-height: 56rem;
  margin: auto;
  margin-top: 30rem;
  cursor:pointer;
}
.froms{
  width: 588rem;
  margin: auto;
  margin-top: 80rem;
  padding-top: 1rem;
  box-sizing: border-box;
}
.title{
  font-weight: 600;
  font-size: 40rem;
  color: #000000;
}
.tips{
  font-weight: 400;
  font-size: 16rem;
  color: #686F86;
  margin-top: 10rem;
}
.step{
  width: 484rem;
  /* display: flex;
  justify-content: space-between; */
  border-top: 2rem solid #37B889;
  position: relative;
  margin-top: 30rem;
  margin-left: 68rem;
}
.step1{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: -11rem;
  left: -22rem;
}
.step2{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: -11rem;
  right: -22rem;
}
.red{
  width: 22rem;
  height: 22rem;
  background: #37B889;
  font-weight: 400;
  font-size: 12rem;
  color: #FFFFFF;
  text-align: center;
  line-height: 22rem;
  border-radius: 50%;
}
.red_no{
  background: #37B889;
  color: #FFFFFF;
}
.tips_min{
  font-weight: 400;
  font-size: 14rem;
  color: #686F86;
  margin-top: 5rem;
}
.title2{
  font-weight: 600;
  font-size: 16rem;
  color: #000000;
  margin-top: 50rem;
  margin-bottom: 16rem;
}
.bto{
    width: 100%;
    position: fixed;
    bottom: 0;
}
</style>